export const SUPPORTED_LOCALES = {
    vi: {
        code: "VN",
        link: "../img/icons/vn-flag.svg",
    },
    en: {
        code: "EN",
        link: "../img/icons/en-flag.svg",
    },
    ko: {
        code: "KO",
        link: "../img/icons/korea-flag.svg",
    }
}