<template>
  <div class="action__translate cursor-pointer">
    <div class="translates translate-active flex items-center" v-if="dataLang">
      <img :src="dataLang[locale]?.link" alt="flag" />
      <span>{{ dataLang[locale]?.code }}</span>
      <img
        class="header__current-icon"
        src="../../assets/img/header/arrow-down.svg"
        alt=""
      />
    </div>
    <ul class="translate translate-other">
      <div class="translate-close absolute">
        <img src="../../assets/img/icons/arrow-down.svg" alt="" />
      </div>
      <li class="flex items-center" v-for="(value, key) in dataLang" :key="key" @click="setLanguage(key)">
        <img :src="value.link" alt="vn flag" />
        <span>{{value.code}}</span>
      </li>
    </ul>
  </div>
</template>
<script>
import { SUPPORTED_LOCALES } from '@/constants/locale';
import { mapActions, mapGetters, mapMutations } from "vuex";
import SettingsSerive from "@/services/SettingService";
export default {
  components: {},
  props: {},
  data() {
    return {
      dataLang: '',
    };
  },
  computed: {
    ...mapGetters(["locale"]),
  },
  mounted() {
    this.getDataLang();
  },
  methods: {
    ...mapActions(["setLang"]),
    ...mapMutations(["setDataLang"]),
    setLanguage(lang) {
      this.setLang(lang);
      this.$router.go();
    },
    async getDataLang () {
      await SettingsSerive.getAmberHillSetting().then((resp) => {
          this.dataLang = resp.data.locales;
          let output = {};
          this.dataLang = Object.keys(this.dataLang).map((key) => {
              output[key] = {...SUPPORTED_LOCALES[key], ...this.dataLang[key]}
          });
          this.dataLang = output;
          this.setDataLang(output);
        })
        .catch(() => {});
    },
  },
};
</script>
