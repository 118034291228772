<template>
  <footer class="footer-animation" id="ft-b" data-aos="fade-up">
    <div class="footer-content pc content-k">
      <div class="footer-content__menu flex justify-between">
        <div class="logo-f">
          <router-link :to="'/'">
            <img src="../assets/img/logo-pc.svg" alt="logo"
          /></router-link>
        </div>
        <div class="menu-detail">
          <div class="menu-item flex" v-if="dataMenu">
            <div v-for="(item, index) in dataMenu" :key="index" class="item">
              <div
                class="item__title"
                @click="
                  activeMenuP(
                    index,
                    index === 0 || index === 4 || index === 5 ? true : 0
                  )
                "
              >
                <router-link :to="item.link">{{ item.name }}</router-link>
              </div>
              <ul class="item__content">
                <li
                  class="lg:poiter"
                  v-for="(sub, index2) in item.sub"
                  :key="index2"
                  @click="activeMenuP(index, index2)"
                >
                  <router-link :to="sub.link">{{ sub.name }}</router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="menu-social">
          <div class="social__title">
            {{ trans("frontend.social network") }}
          </div>
          <ul class="social__list-item" v-if="info">
            <li class="lg:poiter">
              <a
                :href="info?.facebook_link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="../assets/img/footer/facebook.svg" alt=""
              /></a>
            </li>
            <li class="lg:poiter">
              <a
                :href="info?.youtube_link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="../assets/img/footer/youtube.svg" alt=""
              /></a>
            </li>
          </ul>
        </div>
      </div>
      <div class="footer-content__mail flex">
        <div class="register-email flex justify-between">
          <div class="register-email__title">
            {{ trans("frontend.form.title.fill_registration") }}
          </div>
          <div class="register-email__input">
            <input
              type="text"
              name=""
              id=""
              :placeholder="trans('frontend.placeholder.email')"
            />
          </div>
          <div class="register-email__button lg:poiter">
            <a href="#">{{ trans("frontend.button.send to") }}</a>
          </div>
        </div>
        <div class="scroll-top flex lg:poiter">
          <div class="scroll__title">{{ trans("frontend.button.go top") }}</div>
          <div class="scroll__icon" @click="scrollToTop">
            <img src="../assets/img/icons/scroll-up-orange.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="footer-content-mb mb content-k">
      <div class="footer-content-mb__menu">
        <div class="logo-f flex justify-center">
          <img src="../assets/img/logo-pc.svg" alt="logo" />
        </div>
        <div class="menu-detail">
          <ul>
            <li
              v-for="(item, index) in dataMenu"
              :key="index"
              @click="
                activeMenuP(
                  index,
                  index === 0 || index === 4 || index === 5 ? true : 0
                )
              "
            >
              <router-link :to="item.link">{{ item.name }}</router-link>
            </li>
          </ul>
        </div>
        <div class="sub-mail">
          <div class="register-email">
            <div class="register-email__title">
              {{ trans("frontend.form.title.fill_registration") }}
            </div>
            <div class="register-email__input">
              <input
                type="text"
                name=""
                id=""
                :placeholder="trans('frontend.placeholder.email')"
              />
            </div>
            <div class="register-email__button lg:poiter">
              <a href="#">{{ trans("frontend.button.send to") }}</a>
            </div>
          </div>
        </div>
        <div class="menu-social">
          <div class="social__title">
            {{ trans("frontend.social network") }}
          </div>
          <ul class="social__list-item" v-if="info">
            <li class="lg:poiter">
              <a
                :href="info?.facebook_link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="../assets/img/footer/facebook.svg" alt=""
              /></a>
            </li>
            <li class="lg:poiter">
              <a
                :href="info?.youtube_link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="../assets/img/footer/youtube.svg" alt=""
              /></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="copy-right">
      <div class="copy-right__content flex justify-between content-k">
        <p>© 2021 Amber Hills Golf & Resort. All Rights Reserved.</p>
        <p class="text-center">CÔNG TY CỔ PHẦN QNK BẮC GIANG <br>Thôn Bình An, Xã Tiền Phong, Huyện Yên Dũng, Tỉnh Bắc Giang, Việt Nam</p>
        <p>
          {{ trans("frontend.designed by") }}
          <a href="https://saijinagency.com"
            ><span class="saijin">Saijin Agency</span>
          </a>
        </p>
      </div>
    </div>
  </footer>
  <div id="fb-root" />
  <div id="fb-customer-chat" class="fb-customerchat" />
</template>
<script>
/* global $ */
import CommonSerive from "@/services/CommonSerive";
import { mapActions, mapMutations } from "vuex";
export default {
  data() {
    return {
      dataMenu: [
        {
          name: "Sân Golf",
          link: "/golf-course",
          sub: [
            {
              name: "Sân",
              link: "/golf-course/detail",
            },
            {
              name: "Bảng phí",
              link: "/price-list",
            },
            {
              name: "Caddy",
              link: "/caddy",
            },
          ],
        },
        {
          name: "TIỆN ÍCH",
          link: "/restaurant",
          sub: [
            {
              name: "Nhà Hàng",
              link: "/restaurant",
            },
            {
              name: "Tiện Ích Khác",
              link: "/other-utilites",
            },
          ],
        },
        {
          name: "BIỆT THỰ",
          link: "/biet-thu-nghi-duong",
          sub: [
            {
              name: "Biệt Thự Cho Thuê",
              link: "/biet-thu-nghi-duong",
            },
            {
              name: "Biệt Thự Nghỉ Dưỡng",
              link: "",
            },
          ],
        },
        {
          name: "TIN TỨC VÀ SỰ KIỆN",
          link: "/news",
          sub: [
            {
              name: "Tin Tức",
              link: "/news",
            },
            {
              name: "Giải Đấu",
              link: "",
            },
            {
              name: "HIO & Eagle",
              link: "",
            },
            {
              name: "Tin Khuyến Mại",
              link: "/news/promos",
            },
          ],
        },
        {
          name: "HỘI VIÊN",
          link: "/member",
          sub: [
            {
              name: "Hiệp Hội Golf VGA",
              link: "/member/VGA",
            },
            {
              name: "Phí Hội Viên",
              link: "/member/cross",
            },
            {
              name: "Quyền Lợi Hội Viên",
              link: "/member/benefit",
            },
            {
              name: "Đăng Ký Hội Viên",
              link: "/member/register",
            },
          ],
        },
      ],
      info: "",
    };
  },
  mounted() {
    if (
      localStorage.getItem("locale") == null ||
      localStorage.getItem("locale") == "null"
    ) {
      this.setLang("vi");
    } else {
      this.setLang(localStorage.getItem("locale"));
    }
    $(document).ready(function () {
      $(".menu-toggle .item").click(function (event) {
        if (event.target.classList.value.includes("active")) {
          $(".menu-toggle .item").removeClass("active");
          return;
        }
        $(".menu-toggle .item").removeClass("active");
        event.target.classList.toggle("active");
      });
      $(".sub-menu li").click(function (event2) {
        $(".menu-b li a").removeClass("active");
        event2.target.classList.toggle("active");
      });
    });
    // this.$nextTick(function () {

    // });

    this.getDataMenu();
    this.getInfo();
    this.setFb();
  },
  methods: {
    ...mapActions(["setLang"]),
    ...mapMutations(["setInfo", "setDataMenu"]),
    scrollToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    activeMenuP(index, sub) {
      this.$store.commit("setMenuActive", index);
      if ((index === 0 || index === 4 || index === 5) && sub === true) {
        this.$store.commit("setSubActive", true);
      } else {
        this.$store.commit("setSubActive", sub);
      }
    },
    async getDataMenu() {
      await CommonSerive.getMenu()
        .then((resp) => {
          this.dataMenu = resp.data.data;
          this.dataMenu = this.dataMenu.filter((x, index) => {
            if (index != 5 && index != 6 && index != 7) {
              return x;
            }
          });
          this.setDataMenu(resp.data.data);
        })
        .catch(() => {});
    },
    async getInfo() {
      await CommonSerive.getInformation()
        .then((resp) => {
          this.info = resp.data.data;
          this.setInfo(this.info);
        })
        .catch(() => {});
    },
    setFb() {
      $(document).ready(function () {
        var chatbox = document.getElementById("fb-customer-chat");
        if (chatbox) {
          chatbox.setAttribute("page_id", "1788643954787205");
          chatbox.setAttribute("attribution", "biz_inbox");
        }
        window.fbAsyncInit = function () {
          // eslint-disable-next-line no-undef
          FB.init({
            xfbml: true,
            version: "v13.0",
          });
        };

        (function (d, s, id) {
          var js,
            fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) return;
          js = d.createElement(s);
          js.id = id;
          js.src =
            "https://connect.facebook.net/vi_VN/sdk/xfbml.customerchat.js";
          fjs.parentNode.insertBefore(js, fjs);
        })(document, "script", "facebook-jssdk");
      });
    },
  },
};
</script>
