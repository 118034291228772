<template>
  <div :class="activeMenu ? 'show-hide header-detail' : 'header-detail'">
    <div class="detail-main">
      <div class="detail-main__top flex">
        <div class="detail-menu flex items-center" @click="closeMenu">
          <img
            class="menu-icon"
            src="../../assets/img/icons/close-menu.svg"
            alt="menu"
          />
          <span>MENU</span>
        </div>
      </div>
      <div class="detail-main__logo flex justify-center w-full mb-12">
        <div class="logo-pc">
          <router-link :to="'/'"
            ><img src="../../assets/img/logo/amber.svg" alt="menu"
          /></router-link>
        </div>
      </div>
      <div class="detail-main__content pc">
        <div class="list-menu flex flex-wrap justify-around" v-if="dataMenu">
          <div class="item" v-for="(item, index) in dataMenu" :key="index">
            <div
              class="item__title"
              @click="
                setMenuActiveM(index, index === 0 || index === 5 ? true : 0)
              "
            >
              <router-link :to="item.link">{{ item.name }}</router-link>
            </div>
            <div class="line"></div>
            <div class="item__content">
              <div class="sub-menu">
                <ul>
                  <li
                    @click="closeMenu(index, index2)"
                    v-for="(sub, index2) in item.sub"
                    :key="index2"
                  >
                    <router-link
                      :to="
                        sub.link + (sub.folder_id ? '?id=' + sub.folder_id : '')
                      "
                      >{{ sub.name }}</router-link
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="item">
            <router-link class="item__button" :to="'/contact'" @click="activeBook()"
              > <a>ĐẶT CHỖ</a></router-link
            >
          </div>
        </div>
      </div>
      <div class="detail-main__content-mb mb">
        <div class="list-menu">
          <ul
            class="menu-toggle"
            id="myDIV"
            v-if="dataMenu"
            @click="toggleMenu"
          >
            <li class="item" v-for="(item, index) in dataMenu" :key="index">
              <router-link
                @click="closeMenu(index, index === 0 || index === 4 ? 0 : 1)"
                :to="item.link"
                >{{ item.name }}</router-link
              >
              <img
                class="sub-icon"
                src="../../assets/img/header/arrow-down.svg"
                alt=""
              />
              <ul class="sub-menu">
                <li
                  @click="closeMenu(index, index2)"
                  v-for="(sub, index2) in item.sub"
                  :key="index2"
                >
                  <router-link
                    :to="
                      sub.link + (sub.folder_id ? '?id=' + sub.folder_id : '')
                    "
                    >{{ sub.name }}</router-link
                  >
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div class="action-mb">
          <div class="action-mb__translate cursor-pointer">
            <div
              class="translates translate-active flex items-center"
              v-if="dataLang"
            >
              <img :src="dataLang[locale]?.link" alt="flag" />
              <span>{{ dataLang[locale]?.code }}</span>
              <img
                class="header__current-icon"
                src="../../assets/img/header/arrow-down.svg"
                alt=""
              />
            </div>
            <ul class="translate translate-other">
              <div class="translate-close absolute">
                <img src="../../assets/img/icons/arrow-down.svg" alt="" />
              </div>
              <li
                class="flex items-center"
                v-for="(value, key) in dataLang"
                :key="key"
                @click="setLanguage(key)"
              >
                <img :src="value.link" alt="vn flag" />
                <span>{{ value.code }}</span>
              </li>
            </ul>
          </div>
          <div
            class="action-mb__search flex items-center cursor-pointer"
            @click="activeSearch = !activeSearch"
          >
            <img src="../../assets/img/header/search-top.svg" alt="search" />
            <span>Tìm kiếm</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="seacrh-detail" v-show="activeSearch">
    <div class="seacrh-detail__icon-close">
      <img
        @click="closeSearch"
        class="menu-icon"
        src="../../assets/img/icons/close-menu.svg"
        alt="menu"
      />
    </div>
    <div class="seacrh-detail__main">
      <div class="search-title">Bạn Muốn Tìm Kiếm Gì?</div>
      <div class="search-content">
        <img
          class="icon-search"
          src="../../assets/img/icons/search-black.svg"
          alt="menu"
        />
        <input type="text" v-model="content" />
        <div class="s-button">
          <a href="#" @click="searchData()">TÌM KIẾM</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed } from "vue";
import { useRoute } from "vue-router";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  props: ["activeMenuP", "activeSearchP"],
  components: {},
  data() {
    return {
      activeMenu: this.activeMenuP,
      activeSearch: this.activeSearchP,
      content: '',
    };
  },
  watch: {
    activeMenuP() {
      this.activeMenu = this.activeMenuP;
    },
    activeSearchP() {
      this.activeSearch = this.activeSearchP;
    },
  },
  emits: ["setPopupMenu", "setPopupSearch"],
  setup(_, { emit }) {
    const router = useRoute();
    const emitMenu = () => {
      emit("setPopupMenu", false);
    };
    const emitSearch = () => {
      emit("setPopupSearch", false);
    };
    return {
      emitMenu,
      emitSearch,
      meta: computed(() => router.meta),
    };
  },
  computed: {
    ...mapGetters(["dataMenu", "locale", "dataLang"]),
  },
  methods: {
    ...mapMutations([
      // "setMenuActive" mutation thành `this.setMenuActive()`.
      "setMenuActive",
      "setSubActive",
    ]),
    ...mapActions(["setLang"]),
    setLanguage(lang) {
      this.setLang(lang);
      this.$router.go();
    },
    setMenuActiveM(index, sub) {
      this.$store.commit("setMenuActive", index);
      if ((index === 0 || index === 4 || index === 5) && sub === true) {
        this.$store.commit("setSubActive", true);
      } else {
        this.$store.commit("setSubActive", sub);
      }
      this.activeMenu = false;
    },
    closeMenu(index, sub) {
      this.setMenuActiveM(index, sub);
      this.emitMenu();
    },
    closeSearch() {
      this.activeSearch = false;
      this.emitSearch();
    },
    activeBook() {
      this.$store.commit("setMenuActive", 6);
      this.$store.commit("setSubActive", 0);
      this.emitMenu();
    },
    onListClicked(event) {
      event.target.classList.toggle("menu-active");
    },
    searchData() {
      console.log(this.content);
      
      this.$router.push({ path: 'search', query: { search: this.content } });
    }
    // async getDataMenu() {
    //   await CommonSerive.getMenu()
    //     .then((resp) => {
    //       this.dataMenu = resp.data.data;
    //     })
    //     .catch(() => {});
    // },
  },
};
</script>
