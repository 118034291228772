import http from "@/http/http-common";
const resource = "/api/frontend";
class CommonSerive {
    getMenu() {
        return http.get(`${resource}/menu`);
    }
    getInformation() {
        return http.get(`${resource}/information`);
    }
}

export default new CommonSerive();